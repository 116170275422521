<template>
  <iframe
    :src="dashboard.url"
    class="iframe"
    id="iframe"
    allowtransparency
    frameborder="0"
    @load="frameLoaded"
  ></iframe>
</template>

<script>
export default {
  props: ["dashboard"],
  methods: {
    frameLoaded: function() {
      window.iFrameResize({}, "iframe");
    },
  },
};
</script>

<style scoped>
.iframe {
  width: 100%;
}
</style>
